import { Component, ElementRef, EventEmitter, HostListener, Inject, NgModule, OnInit, Output, Renderer2, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { HttpService } from 'src/app/services/http.service';
import { DataSharingServiceService } from 'src/app/services/data-sharing-service.service';
import { Location } from '@angular/common';

interface UserTableRow {
  id: number;
  first_name: string;
  last_name: string;
  username: string;
  email: string;
  role_id: string;
  created_at: string;
  last_login_at: string;
  reachout_flag: any;
  options: any;
  isHovered?: boolean; // Optional property to track hover state
  isDropdownOpen?: boolean;
  is_suspended: any;
  license_dtls: any[];
}

@Component({
  selector: 'app-user-management',
  templateUrl: './user-management.component.html',
  styleUrls: ['./user-management.component.css'],
})
export class UserManagementComponent implements OnInit {
  @Output() backEmitter = new EventEmitter<any>();

  compRoute: string = 'manageUser';

  constructor(
    private dataService: DataSharingServiceService,
    private httpSv: HttpService,
    private toster: ToastrService,
    private router: Router,
    public dialog: MatDialog,
    private el: ElementRef,
    private renderer: Renderer2,
    private location: Location
  ) { }
  @HostListener('window:scroll', ['$event'])
  onScroll() {
    this.checkIfLastRowInView();
  }

  ngOnInit(): void {

    this.header = 'Users';
    this.dataService.userData = {};
    this.getAllUser()
    this.role_name = this.dataService.role_id_name
  }
  ngAfterViewInit() {
    setTimeout(() => {
      this.checkIfLastRowInView();
    }, 400)
  }
  navigateBack() {
    this.location.back();
  }
  checkIfLastRowInView() {
    const rows = document.getElementsByClassName('user-row');
    const tbody = document.getElementById('tbody_main_div');
    const tbodyRect = tbody?.getBoundingClientRect();
    const exitsCss = document.getElementsByClassName('dropdown-menu-first-there')
    let lastVisibleRow = 0;
    for (let i = exitsCss.length - 1; i >= 0; i--) {
      exitsCss[i].classList.remove('dropdown-menu-first-there')
    }
    for (let i = rows.length - 1; i >= 0; i--) {
      const rect = rows[i].getBoundingClientRect();

      // Check if the row is within the height of the tbody
      if (tbodyRect && rect.bottom >= tbodyRect.top && rect.top <= tbodyRect.bottom) {
        lastVisibleRow = i;
        break;
      }
    }

    // Add the class to the last visible row
    if (lastVisibleRow) {
      rows[lastVisibleRow - 2].classList.add('dropdown-menu-first-there');
      rows[lastVisibleRow - 1].classList.add('dropdown-menu-first-there');
      rows[lastVisibleRow].classList.add('dropdown-menu-first-there');
    }
  }
  header: string = '';
  raisedHandIcon: string = '../../../../assets/raisedHand.svg'
  allUsers: UserTableRow[] = [];
  apiAllUsers: any;
  filterTxt: any;
  role_name: any
  goBack() {
    this.backEmitter.emit('home')
  }
  filter() {
    if (this.filterTxt.length > 0) {
      this.allUsers = this.apiAllUsers
      let filterData = this.allUsers.filter((user: any) => (user.first_name?.toLowerCase() + ' ' + user.last_name?.toLowerCase() + ' ' + user.email).includes(this.filterTxt.toLowerCase()))
      this.allUsers = filterData
    }
    else {
      this.allUsers = this.apiAllUsers
    }
  }
  changeCompRoute(route: any) {
    if (route == 'addUser') {
      this.dataService.addEditUser = route;
      this.router.navigate(['/add-user']);
    }
  }

  getRole(role_id: any) {
    return this.role_name[role_id];
  }

  getDateFormat(timestamp: any, isFormated: boolean = true) {
    let formattedDate;
    if (timestamp !== null) {
      if (!formattedDate) timestamp.split(' ').join('T')
      const dateObject = new Date(timestamp);
      const year = dateObject.getFullYear().toString().slice(-2);
      const month = (dateObject.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-based
      const day = dateObject.getDate().toString().padStart(2, '0');

      formattedDate = `${day}/${month}/${year}`;
    } else {
      formattedDate = 'N/A';
    }

    return formattedDate;
  }

  onRowHover(isHovered: boolean, user: UserTableRow): void {
    user.isHovered = isHovered;
    if (!isHovered) {
      user.isDropdownOpen = false;
    }
  }

  addNewUser() {
    this.dataService.addEditUser = 'addUser';
    this.router.navigate(['/add-user']);
  }
  getAllUser() {
    this.httpSv.getAllUserList().subscribe((res: any) => {
      if (res['status']) {
        res['results'] = res['results'].sort((a: any, b: any) => {
          if (a.reachout_flag) return -1
          else return 1
        })
        this.allUsers = res['results']
        this.apiAllUsers = res['results']
      } else {
        this.toster.error('Oops! Someting went wrong', '', { timeOut: 2000 })
      }
    }, (err: any) => {
      this.toster.error('Oops! Someting went wrong', '', { timeOut: 2000 })
    })
  }
  openAssignment(user: any) {
    if (user.role_id == 3) this.onOptionClick('assignGame', user)
  }

  onOptionClick(option: string, user: any) {
    // Handle the option click here
    if (option == 'assignGame') {
      sessionStorage.setItem('expiredSubcription', user.expiredSubcription)
      this.router.navigate(['/assign-game'], { queryParams: { id: user.id } })
    } else if (option == 'editUser') {
      // this.dataService.userData = user;
      // this.dataService.addEditUser = 'editUser';
      this.router.navigate(['/edit-user'], { queryParams: { id: user.id } });
    }
    else if (option == 'suspendUser') {
      const dialogRef = this.dialog.open(suspendActivateUserDialog, {
        hasBackdrop: true,
        disableClose: true,
        data: 'Are you sure you want to suspend this user ?'
      })
      dialogRef.afterClosed().subscribe((res: boolean) => {
        if (res) {
          const data = {
            user_id: user.id,
            type: 'suspend'
          }
          this.httpSv.updateUserSuspendActivate(data).subscribe((res: any) => {
            if (res['status']) {
              this.toster.success("User's account suspended sucessfully", '', { timeOut: 2000 })
              this.getAllUser()
            } else {
              this.toster.error('Oops! Something went wrong', '', { timeOut: 2000 })
            }
          }, (err: any) => {
            this.toster.error(err.error.message, '', { timeOut: 2000 })
          })
        }
      })
    }
    else if (option == 'activateUser') {
      const dialogRef = this.dialog.open(suspendActivateUserDialog, {
        hasBackdrop: true,
        disableClose: true,
        data: 'Are you sure you want to activate this user ?'
      })
      dialogRef.afterClosed().subscribe((res: boolean) => {
        if (res) {
          const data = {
            user_id: user.id,
            type: 'activate'
          }
          this.httpSv.updateUserSuspendActivate(data).subscribe((res: any) => {
            if (res['status']) {
              this.toster.success("User's account activated sucessfully", '', { timeOut: 2000 })
              this.getAllUser()
            } else {
              this.toster.error('Oops! Something went wrong', '', { timeOut: 2000 })
            }
          }, (err: any) => {
            this.toster.error(err.error.message, '', { timeOut: 2000 })
          })
        }
      })
    }
    else if (option == 'activity') {
      this.dataService.selected = user
      this.router.navigate(['/game-tracking'])
    }
  }

  toggleDropdown(user: any) {
    // Toggle the dropdown state for the user
    user.isDropdownOpen = !user.isDropdownOpen;
  }
  subcritionExpriedCheck(userDtls: any, type: string | null = null) {
    let returnFlag
    let subcription: any[] = userDtls.license_dtls
    if (subcription.length == 0) returnFlag = false
    else if (type == null) {
      const digitalSubcription = subcription.filter((ele: any) => ele.game_type == '1')
      const physicalSubcription = subcription.filter((ele: any) => ele.game_type == '2')
      const digitalFlag = digitalSubcription.every((ele: any) => (ele.status == 0 && ele.is_active === 0) || ele.no_of_licenses_left == 0)
      const physicalFlag = physicalSubcription.every((ele: any) => ele.status == 0 && ele.is_active === 0)
      returnFlag = digitalSubcription.length > 0 && physicalSubcription.length > 0 ? (digitalFlag || physicalFlag) : digitalSubcription.length > 0 ? digitalFlag : physicalSubcription.length > 0 ? physicalFlag : false
    }
    else if (type == 'digitalphysical') {
      const digitalSubcription = subcription.filter((ele: any) => ele.game_type == '1')
      const physicalSubcription = subcription.filter((ele: any) => ele.game_type == '2')
      const digitalFlag = digitalSubcription.every((ele: any) => (ele.status == 0 && ele.is_active === 0) || ele.no_of_licenses_left == 0)
      const physicalFlag = physicalSubcription.every((ele: any) => ele.status == 0 && ele.is_active === 0)
      returnFlag = digitalSubcription.length > 0 && physicalSubcription.length > 0 ? (digitalFlag && physicalFlag) : false
    }
    else if (type == 'digital') {
      const digitalSubcription = subcription.filter((ele: any) => ele.game_type == '1')
      returnFlag = digitalSubcription.length > 0 ? digitalSubcription.every((ele: any) => (ele.status == 0 && ele.is_active === 0) || ele.no_of_licenses_left == 0) : false
    }
    else if (type == 'physical') {
      const physicalSubcription = subcription.filter((ele: any) => ele.game_type == '2')
      returnFlag = physicalSubcription.length > 0 ? physicalSubcription.every((ele: any) => ele.status == 0 && ele.is_active === 0) : false
    }
    else {
      returnFlag = false
    }
    if (returnFlag) userDtls['expiredSubcription'] = type
    return returnFlag
  }

}


// Add New User 
// Add New User 
// Add New User 
// Add New User 

@Component({
  selector: 'app-user-add-user',
  templateUrl: './user-new-add-dialog.html',
  styleUrls: ['./user-management.component.css']
})
export class UserAddComponent implements OnInit {
  constructor(
    private dataService: DataSharingServiceService,
    private httpSv: HttpService,
    private router: Router,
    private toster: ToastrService,
    private route: ActivatedRoute,
    private location: Location,
  ) {
  }

  ngOnInit(): void {
    this.options = JSON.parse(JSON.stringify(this.dataService.role_id_name))
    this.roles = {
      // 1: 'Super Admin',
      2: 'Content Admin',
      3: 'Facilitator'
    }
    this.route.queryParams.subscribe((result: any) => {
      if (result.id) {
        this.httpSv.userDetails(result.id).subscribe((res: any) => {
          const data = res['result'][0]
          for (const key in data) {
            if (this.inputFields.hasOwnProperty(key)) {
              if (key == 'role_id') {
                this.userData['user_type'] = data[key];
              } else {
                this.userData[key] = data[key];
              }
            }
          }
          this.userId = result.id;
          this.header = 'Edit User';
          this.addEditUser = 'editUser'
          this.selectedRole = this.userData.user_type;
        })
      }
      else {
        this.header = 'Add User';
        this.addEditUser = 'addUser'
      }

    })
    // if(Object.keys(this.dataService.userData).length > 0){
    //   const data = this.dataService.userData;
    //   for (const key in data) {
    //     if (this.inputFields.hasOwnProperty(key)) {
    //       if(key == 'role_id'){
    //         this.userData['user_type'] = data[key];
    //       } else{
    //         this.userData[key] = data[key];
    //       }
    //     }
    //   }
    //   this.userId = this.dataService.userData.id;
    // }

    // if(this.dataService.addEditUser == ''){
    //   this.router.navigate(['/user-list']);
    // } else{
    //   this.addEditUser = this.dataService.addEditUser;
    //   if(this.dataService.addEditUser == 'addUser'){
    //     this.header = 'Add User';
    //   } else if(this.dataService.addEditUser == 'editUser'){
    //     this.header = 'Edit User';
    //   }
    // }


  }
  navigateBack() {
    this.location.back();
  }
  emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  passwordRegex = /^(?=.*\d)(?=.*[a-zA-Z])(?=.*[\W_])[\w\d\W_]{6,}$/;
  // passwordRegex = /^(?=.*\d)(?=.*[a-zA-Z])[\w\d]{6,}$/;

  header: string = '';
  tempPassword: string = 'XXXXXXXX';

  options: any = {}

  addEditUser = '';
  userId = '';
  isDropdownOpen: boolean = true;
  selectedRole: any;

  userData: any = {
    first_name: '',
    last_name: '',
    email: '',
    contact: '',
    password: '',
    user_type: 0,
    organisation: '',
    bio: ''
  }

  inputFields: any = {
    first_name: 'First Name',
    last_name: 'Last Name',
    email: 'Email Address',
    contact: 'Phone Number',
    password: 'Password',
    user_type: 'User Type',
    role_id: 'User Type',
    organisation: 'Organisation',
    bio: 'Bio/brief'
  }

  roles: any = {}

  changeCompRoute(route: any) {
    if (route == 'addUser') {
      this.router.navigate(['/user-list']);
    }
  }

  clearInputValues() {
    this.userData = {
      first_name: '',
      last_name: '',
      email: '',
      contact: '',
      password: '',
      user_type: 0,
      organisation: '',
      bio: ''
    }
  }

  onOptionClick(option: string,) {
    // Handle the option click here
    if (option == 'assignGame') {
    } else if (option == 'editUser') {
      this.dataService.addEditUser = 'editUser';
      this.router.navigate(['/edit-user']);
    }
  }

  onSelectChange(event: any) {
    this.userData.user_type = 1;
    this.selectedRole = event.target.value;
    this.userData.user_type = Number(this.selectedRole);
  }

  toggleDropdown() {
    // Toggle the dropdown state for the user
    this.isDropdownOpen = !this.isDropdownOpen;
  }

  addNewUser() {
    let emptyField = false;
    let fieldName = '';

    const userInputData = { ...this.userData };

    if (this.addEditUser == 'editUser') {
      if (userInputData.password == '') {
        delete userInputData.password;
      }
      delete userInputData.email;
    }

    for (const key in userInputData) {
      if (userInputData.hasOwnProperty(key) && !(key == 'contact' || key == 'bio')) {
        const value = userInputData[key];
        if (value == '' || value == 0) {
          emptyField = true;
          fieldName = key;
          break;
        }
      }
    }
    if (emptyField) {
      this.toster.warning(`Please fill the ${this.inputFields[fieldName]}`, '', { timeOut: 2000 })
    } else {
      if ((userInputData.hasOwnProperty('password') && this.passwordRegex.test(userInputData.password)) || !userInputData.hasOwnProperty('password')) {
        if (this.addEditUser == 'addUser') {
          if (this.emailRegex.test(userInputData.email)) {
            if (!userInputData.bio) delete userInputData.bio
            if (!userInputData.contact) delete userInputData.contact
            else userInputData.contact = parseInt(userInputData.contact)
            userInputData.role_id = userInputData.user_type
            delete userInputData.user_type
            if (userInputData.contact) {
              if (userInputData.contact.length != 10) {
                this.toster.error('The contact number must be exactly 10 digits.', '', { timeOut: 2000 })
                return
              }
            }
            this.httpSv.addNewUser(userInputData).subscribe((res: any) => {
              if (res['status']) {
                this.toster.success('User added successfully', '', { timeOut: 2000 })
                this.router.navigate(['/user-list']);
              } else {
                this.toster.error('Opps! Something went wrong', '', { timeOut: 2000 })
              }
            }, (err: any) => {
              let errMsg
              for (const key in err.error) {
                errMsg = err.error[key]
              }
              this.toster.error(errMsg, '', { timeOut: 2000 })
            })
          } else {
            this.toster.warning('Please enter a valid email id', '', { timeOut: 2000 })
          }
        } else if (this.addEditUser == 'editUser') {
          if (!userInputData.bio) delete userInputData.bio
          userInputData.role_id = userInputData.user_type
          delete userInputData.user_type
          if (!userInputData.contact) delete userInputData.contact
          else userInputData.contact = parseInt(userInputData.contact)
          if (userInputData.contact) {
            if (userInputData.contact.toString().length != 10) {
              this.toster.error('The contact number must be exactly 10 digits.', '', { timeOut: 2000 })
              return
            }
          }
          this.httpSv.updateUser(this.userId, userInputData).subscribe((res: any) => {
            if (res['status']) {
              this.toster.success('User updated successfully', '', { timeOut: 2000 })
              this.router.navigate(['/user-list']);
            } else {
              this.toster.error('Opps! Something went wrong', '', { timeOut: 2000 })
            }
          }, (err: any) => {
            this.toster.error(err.error.message, '', { timeOut: 2000 })
          })
        }
      } else {
        this.toster.warning('Password should have minimum 6 digits containing atleast one number & character.', '', { timeOut: 4000 })
      }
    }
  }
  firstNameLimitErr: boolean = false
  lastNameLimitErr: boolean = false
  onKeyDown(type: string, event: any) {
    if (
      event.key === 'Backspace' ||
      event.key === 'Delete' ||
      event.key === 'ArrowLeft' ||
      event.key === 'ArrowRight' ||
      event.key === 'ArrowUp' ||
      event.key === 'ArrowDown' ||
      event.key === 'Control' ||
      event.key === 'Shift' ||
      event.key === 'Tab' ||
      event.key === 'Enter' ||
      event.key === 'CapsLock' ||
      (event.ctrlKey && (event.key === 'c' || event.key === 'a' || event.key === 'x'))
    ) {
      return;
    }
    else if (type == 'first') {
      if (this.userData.first_name.length == 15) {
        event.preventDefault();
        this.firstNameLimitErr = true
        setTimeout(() => {
          this.firstNameLimitErr = false
        }, 2000)
      } else return
    }
    else if (type == 'last') {
      if (this.userData.last_name.length == 15) {
        event.preventDefault();
        this.lastNameLimitErr = true
        setTimeout(() => {
          this.lastNameLimitErr = false
        }, 2000)
      } else return
    }
  }
  checkDataLength(data: any, type: any) {
    if (data.length > 15) {
      if (type == 'first') {
        this.userData.first_name = this.userData.first_name.slice(0, 15)
        this.firstNameLimitErr = true
        setTimeout(() => {
          this.firstNameLimitErr = false
        }, 2000)
      } else {
        this.userData.last_name = this.userData.last_name.slice(0, 15)
        this.lastNameLimitErr = true
        setTimeout(() => {
          this.lastNameLimitErr = false
        }, 2000)
      }
    }
  }
}

@Component({
  selector: 'suspend-activate-user-dialog',
  templateUrl: './suspend-activate-user-dilog.html',
  encapsulation: ViewEncapsulation.None
})
export class suspendActivateUserDialog implements OnInit {
  constructor(
    private toster: ToastrService,
    public dialogRef: MatDialogRef<suspendActivateUserDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private router: Router
  ) { }
  ngOnInit(): void {
    this.dialogTxt = this.data
  }
  dialogTxt: string = ''
  close() {
    this.dialogRef.close(false)
  }
  confirm() {
    this.dialogRef.close(true)
  }
}