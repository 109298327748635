<div>
    <div class="user_card_main_div mlr_2rem ">
        <div class="sub_card_header"
            [ngClass]="userSubscriptionDtls.indicator == 'both' ? 'digitalphysical_card_header': userSubscriptionDtls.indicator == 'physical' ? 'physical_card_header' : 'digital_card_header' ">
            <div style="color: #0D0D0D;">
                <span>{{userSubscriptionDtls.user_dtls[0].name}}</span>
                <span>&nbsp;</span>
                <span>|</span>
                <span>&nbsp;</span>
                <span>{{userSubscriptionDtls.user_dtls[0].role_name}}</span>
            </div>
            <div>
                <span *ngIf="userSubscriptionDtls.indicator == 'both'" class="bold_text">
                    <span *ngIf="!diInactiveFlag;" style="color: #12C070;">Digital Active</span>
                    <span *ngIf="diInactiveFlag;" style="color:#F9244C;">Digital Inactive</span>
                    <span>&nbsp;</span>
                    <span [ngClass]="diInactiveFlag ? 'colRed' : 'colGreen'">&</span>
                    <span>&nbsp;</span>
                    <span *ngIf="!pyInactiveFlag;" style="color: #12C070;">Physical Active</span>
                    <span *ngIf="pyInactiveFlag;" style="color:#F9244C;">Physical Inactive</span>
                </span>
                <span *ngIf="userSubscriptionDtls.indicator == 'digital'" class="bold_text">
                    <span *ngIf="!diInactiveFlag;" style="color: #12C070;">Digital Active</span>
                    <span *ngIf="diInactiveFlag;" style="color:#F9244C;">Digital Inactive</span>
                </span>
                <span *ngIf="userSubscriptionDtls.indicator == 'physical'" class="bold_text">
                    <span *ngIf="!pyInactiveFlag;" style="color: #12C070;">Physical Active</span>
                    <span *ngIf="pyInactiveFlag;" style="color:#F9244C;">Physical Inactive</span>
                </span>
            </div>
            <div style="color:#434343;">
                <span>User Since:</span>
                <span>&nbsp;</span>
                <span>{{convertDate(userSubscriptionDtls.user_dtls[0].user_since)}}</span>
                <br />
                <span>Last Login:</span>
                <span>&nbsp;</span>
                <span>{{convertDate(userSubscriptionDtls.user_dtls[0].last_login)}}</span>
            </div>
        </div>
        <!-- -------------------- PHYSICAL CARD---------------- -->
        <div style="position: relative;"
            *ngIf="userSubscriptionDtls.indicator == 'both' || userSubscriptionDtls.indicator == 'physical'">
            <div class="label_indicator_py">
                Physical
            </div>
            <div class="physical_sub_dtls_main_div">
                <div class="physical_sub_dtls_ele_div physical_sub_dtls_ele_heading">NAME OF THE GAMES</div>
                <div class="physical_sub_dtls_ele_div physical_sub_dtls_ele_heading">NO. OF THE GAME KITS PURCHASED
                </div>
                <div class="physical_sub_dtls_ele_div physical_sub_dtls_ele_heading">GAME RESOURCE ACCESS VALID TILL
                    DATE:</div>
                <ng-container *ngFor="let item of userSubscriptionDtls.physical_dtls">
                    <div class="physical_sub_dtls_ele_div">{{item.game_name}}</div>
                    <div class="physical_sub_dtls_ele_div">{{item.game_kits}}</div>
                    <div class="physical_sub_dtls_ele_div">{{convertEpochToDate(item.end_date)}}</div>
                </ng-container>

            </div>
        </div>
        <!-- -------------------- DIGITAL CARD---------------- -->
        <div style="position: relative;"
            *ngIf="userSubscriptionDtls.indicator == 'both' || userSubscriptionDtls.indicator == 'digital'">
            <div class="label_indicator_di">
                Digital
            </div>
            <div class="horizonatal_line">&nbsp;</div>
            <div class="digital_sub_dtls_main_div">
                <div class="digital_sub_dtls_ele_div">
                    <span class="sub_bold_heading">Licenses Active/Left:</span>
                    <span class="tab_space">&emsp;</span>
                    <span class="bold_text">{{userSubscriptionDtls.digital_dtls[0].no_of_licenses_left}}</span>
                </div>
                <div class="digital_sub_dtls_ele_div">
                    <span class="sub_bold_heading">Last Licenses Update:</span>
                    <span class="tab_space">&emsp;</span>
                    <span class="bold_text">{{userSubscriptionDtls.digital_dtls[0].no_of_licenses}}</span>
                </div>
                <div class="digital_sub_dtls_ele_div">
                    <span class="sub_bold_heading">Valid Till:</span>
                    <span class="tab_space">&emsp;</span>
                    <span class="bold_text">{{convertEpochToDate(userSubscriptionDtls.digital_dtls[0].end_date)}}</span>
                </div>
            </div>
        </div>
        <div *ngIf="userSubscriptionDtls.indicator == 'nothing'">
            <div style="text-align: center;font-weight: 500;padding: 1rem;">
                No Subscription Found
            </div>
        </div>
        <div class="sup_sub_card_btn_main_div" *ngIf="userSubscriptionDtls.indicator != 'nothing'">
            <button class="sup_sub_card_btn" (click)="handleDtlsUseageBtn('Licenses Usage')"
                [ngClass]="{'active_btn_css': current_selection == 'Licenses Usage'}">Licenses Usage</button>
            <button class="sup_sub_card_btn" (click)="handleDtlsUseageBtn('Session Details')"
                [ngClass]="{'active_btn_css': current_selection == 'Session Details'}">Session Details</button>
        </div>
    </div>

    <!----------------------------------- Licenses Usage ------------------------------->
    <div *ngIf="current_selection == 'Licenses Usage'" style="min-height: 25rem;">

        <!-- -----------------------------Date Handler-------------------- -->
        <div class="datePicker_export_main_div mlr_2rem uses_table_main">
            <!-- [ngClass]="{'mar-m-6': marginScrollFalg}" -->
            <div class="datePicker" >
                <div class="datePickerModule">
                    <label>From Date</label>
                    <div>
                        <input type="date" id="datePicker" name="" class="date_inp_dg_inp" 
                            [(ngModel)]="liceseUsesStartDate" (ngModelChange)="onLicenseUsesDateChange()">
                    </div>
                </div>
                <div class="datePickerModule">
                    <label>To Date</label>
                    <div>
                        <input type="date" id="datePicker" name="" class="date_inp_dg_inp"
                            [(ngModel)]="liceseUsesEndDate" (ngModelChange)="onLicenseUsesDateChange()">
                    </div>
                </div>
            </div>
            <button (click)="exportData(lisenseTableData,'licenseUses')"
                *ngIf="liceseTableDtlsShowUpFlag && lisenseUseDtls.license_dtls != 'no session found'"
                class="export_btn">
                Export
                <img src="../../../assets/img/data_export_icon.svg" alt="">
            </button>
        </div>
        <!-- -----------------------------license short dtls---------------------- -->
        <ng-container *ngIf="liceseDtlsShowUpFlag">
            <div class="horizonatal_line mlr_2rem" style="margin-top: 1rem;margin-bottom: 1rem;">&nbsp;</div>
            <div class="session_license_short_dtls_main_div mlr_2rem">
                <div class="session_license_short_dtls_sub_div">
                    <span>Total Session Conducted: </span>
                    <span>{{lisenseUseDtls.session_conducted}},</span>
                </div>
                <div class="session_license_short_dtls_sub_div">
                    <span>Total Licenses Used: </span>
                    <span>{{lisenseUseDtls.license_used}}</span>
                </div>

            </div>
            <div style="margin-top: 1rem;text-align: center; margin-bottom: 4rem;" *ngIf="!liceseTableDtlsShowUpFlag">
                <button class="show_licese_useage_table_btn" (click)="showLicenseTable()">Show Usage
                    details</button>
            </div>
        </ng-container>
        <!-- -------------------license-deduction-table --------------------------- -->
        <ng-container *ngIf="liceseTableDtlsShowUpFlag">
            <div class="mlr_2rem licenses_deduction_dtls_table_div">
                <table class="table table-bordered table-rounded">
                    <thead>
                        <tr>
                            <th scope="col" class="table_header_ele col-3">Session Name</th>
                            <th scope="col" class="table_header_ele col-3">Client</th>
                            <th scope="col" class="table_header_ele col-2">Date</th>
                            <th scope="col" class="table_header_ele col-2">Status</th>
                            <th scope="col" class="table_header_ele col-1">Licenses Used</th>
                        </tr>
                    </thead>
                    <tbody>
                        <ng-container *ngIf="lisenseUseDtls.license_dtls != 'no session found';else liceseUsesNoData">
                            <tr
                                *ngFor="let element of lisenseTableData | slice: (currentPage-1) * pageSize : currentPage * pageSize;let i = index">
                                <th scope="row">{{element.program_name}}</th>
                                <td>{{element.client_name}}</td>
                                <td>{{convertEpochToDate(element.program_date)}}</td>
                                <td>
                                    <span *ngIf="element.isdeleted;else statusNotDeleted"
                                        style="color: #F9244C;">Deleted</span>
                                    <ng-template #statusNotDeleted>
                                        <span *ngIf="element.is_incompleted;else completed" style="color: #F98316;">In
                                            completed</span>
                                        <ng-template #completed>
                                            <span style="color: #12C070;">Completed</span>
                                        </ng-template>

                                    </ng-template>

                                </td>
                                <td>{{element.license_used}}</td>
                            </tr>
                        </ng-container>
                        <ng-template #liceseUsesNoData>
                            <tr>
                                <th colspan="5"
                                    style=" border-right-width: 2px; border-bottom-right-radius: 4px; text-align: center;">
                                    No Data Found</th>
                            </tr>
                        </ng-template>

                    </tbody>
                </table>
            </div>
            <ng-container *ngIf="lisenseUseDtls.license_dtls != 'no session found'">
                <div class="mlr_2rem pagination_main_div">
                    <div class="per_page_element_main_div">
                        <span class="per_page_element_text">Items per page:</span>
                        <div ngbDropdown display="dynamic">
                            <button type="button" class="btn btn-outline-primary per_page_element_btn"
                                id="dropdownConfig" ngbDropdownToggle>{{pageSize}}</button>
                            <div ngbDropdownMenu aria-labelledby="dropdownConfig">
                                <button ngbDropdownItem (click)="pageSize = 10">10</button>
                                <button ngbDropdownItem (click)="pageSize = 25">25</button>
                                <button ngbDropdownItem (click)="pageSize = 50">50</button>
                            </div>
                        </div>
                    </div>

                    <ngb-pagination [collectionSize]="lisenseTableData.length" [(page)]="currentPage" [maxSize]="3"
                        [rotate]="true" [pageSize]="pageSize">
                        <ng-template ngbPaginationPrevious>
                            <span>Previous</span>
                        </ng-template>
                        <ng-template ngbPaginationNext>Next</ng-template>
                    </ngb-pagination>
                </div>
            </ng-container>
        </ng-container>
    </div>


    <!----------------------------------- Session Usage ------------------------------->

    <div *ngIf="current_selection == 'Session Details'" style="min-height: 25rem;">

        <!-- -----------------------------Date Handler-------------------- -->
        <div class="datePicker_export_main_div mlr_2rem uses_table_main">
            <!-- [ngClass]="{'mar-m-6': marginScrollFalg}" -->
            <div class="datePicker">
                <div class="datePickerModule">
                    <label>From Date</label>
                    <div>
                        <input type="date" id="datePicker" name="" class="date_inp_dg_inp"
                            [(ngModel)]="subscriptionDtlsStartDate" (ngModelChange)="onSubscriptionDtlsDateChange()">
                    </div>
                </div>
                <div class="datePickerModule">
                    <label>To Date</label>
                    <div>
                        <input type="date" id="datePicker" name="" class="date_inp_dg_inp"
                            [(ngModel)]="subscriptionDtlsEndDate" (ngModelChange)="onSubscriptionDtlsDateChange()">
                    </div>
                </div>
            </div>
            <button (click)="exportData(subscriptionDtlsData,'subDtls')"
                *ngIf="subscriptionDtlsShowUpFlag && subscriptionDtlsDtls.license_dtls != 'no session found'"
                class="export_btn">
                Export
                <img src="../../../assets/img/data_export_icon.svg" alt="">
            </button>
        </div>
        <!-- -----------------------------Session short dtls---------------------- -->



        <div class="horizonatal_line mlr_2rem" style="margin-top: 1rem;margin-bottom: 1rem;"
            *ngIf="subscriptionDtlsShowUpFlag">&nbsp;</div>
        <div class="session_license_short_dtls_main_div mlr_2rem" *ngIf="subscriptionDtlsShowUpFlag">
            <div class="session_license_short_dtls_sub_div">
                <span>Total Session Conducted: </span>
                <span>{{subscriptionDtlsDtls.session_conducted}},</span>
            </div>
            <div class="session_license_short_dtls_sub_div">
                <span>Total Licenses Used: </span>
                <span>{{subscriptionDtlsDtls.license_used}}</span>
            </div>
        </div>
        <!-- -------------------Session-deduction-table --------------------------- -->
        <ng-container *ngIf="subscriptionDtlsShowUpFlag">
            <div class="mlr_2rem licenses_deduction_dtls_table_div">
                <table class="table table-bordered table-rounded">
                    <thead>
                        <tr>
                            <th scope="col" class="table_super_header_ele" colspan="4">SESSION PLAN</th>
                            <th scope="col" class="table_super_header_ele" colspan="4">SESSION RUN</th>
                        </tr>
                        <tr>
                            <th scope="col" class="table_header_ele col-2">Session Name</th>
                            <th scope="col" class="table_header_ele col-2">Client</th>
                            <th scope="col" class="table_header_ele col-2">Date & Time</th>
                            <th scope="col" class="table_header_ele col-1">#Players</th>
                            <th scope="col" class="table_header_ele col-1">Players</th>
                            <th scope="col" class="table_header_ele col-1">Status</th>
                            <th scope="col" class="table_header_ele col-2">Session Run Time</th>
                            <th scope="col" class="table_header_ele col-1">Licenses used</th>
                        </tr>
                    </thead>
                    <tbody>
                        <ng-container
                            *ngIf="subscriptionDtlsDtls.license_dtls != 'no session found';else subscriptionNoData">
                            <ng-container
                                *ngFor="let item of subscriptionDtlsData | slice: (currentSessionPage-1) * pageSessionSize : currentSessionPage * pageSessionSize;let i = index">
                                <tr (click)="toggleRow(item.id)"
                                    [ngClass]="{'remove_bottom_border': expandedRow == item.id}">
                                    <th scope="row">{{item.program_name}}</th>
                                    <td>{{item.client_name}}</td>
                                    <td
                                        [innerHtml]="convertEpochTimeslot(item.time_slots.start_datetime, item.time_slots.expiry_datetime)">
                                    </td>
                                    <td>{{item.time_slots.planed_player}}</td>
                                    <td>{{item.players ?? 0}}</td>
                                    <td>
                                        <span *ngIf="item.isdeleted;else statusNotDeletedSubs"
                                            style="color: #F9244C;">Deleted</span>
                                        <ng-template #statusNotDeletedSubs>
                                            <span *ngIf="item.is_incompleted;else completedSubs"
                                                style="color: #F98316;">In
                                                completed</span>
                                            <ng-template #completedSubs>
                                                <span style="color: #12C070;">Completed</span>
                                            </ng-template>
                                        </ng-template>
                                    </td>
                                    <td>{{getDurationByEpoch(item.session_starttime,item.session_expirytime)}}</td>
                                    <td>{{item.license_used}}</td>
                                </tr>
                                <tr *ngIf="expandedRow == item.id">
                                    <th colspan="8" style="border-right-width: 2px; border-top: none; padding: 0;">
                                        <div class="expanded-content">
                                            <div class="expanded_content_main_div ">
                                                <div class="expanded_content_header_div table_header_ele">
                                                    Game Names
                                                </div>
                                                <div class="expanded_content_header_div table_header_ele">
                                                    Game Run Time
                                                </div>
                                                <ng-container *ngFor="let ele of item.games">
                                                    <div class="expanded_content_ele_div expanded_content_left_ele_div">
                                                        {{ele.game_name}}
                                                    </div>
                                                    <div
                                                        class="expanded_content_ele_div expanded_content_right_ele_div">
                                                        {{getDurationByEpoch(ele.start_time,ele.expiry_time)}}
                                                    </div>
                                                </ng-container>

                                            </div>
                                        </div>
                                    </th>
                                </tr>
                            </ng-container>
                        </ng-container>
                        <ng-template #subscriptionNoData>
                            <tr>
                                <th colspan="8"
                                    style=" border-right-width: 2px; border-bottom-right-radius: 4px; text-align: center;">
                                    No Data Found</th>
                            </tr>
                        </ng-template>
                    </tbody>
                </table>
            </div>
            <div class="mlr_2rem pagination_main_div" *ngIf="subscriptionDtlsDtls.license_dtls != 'no session found'">
                <div class="per_page_element_main_div">
                    <span class="per_page_element_text">Items per page:</span>
                    <div ngbDropdown display="dynamic">
                        <button type="button" class="btn btn-outline-primary per_page_element_btn" id="dropdownConfig"
                            ngbDropdownToggle>{{pageSessionSize}}</button>
                        <div ngbDropdownMenu aria-labelledby="dropdownConfig">
                            <button ngbDropdownItem (click)="pageSessionSize = 10">10</button>
                            <button ngbDropdownItem (click)="pageSessionSize = 25">25</button>
                            <button ngbDropdownItem (click)="pageSessionSize = 50">50</button>
                        </div>
                    </div>
                </div>
                <ngb-pagination [collectionSize]="subscriptionDtlsData.length" [(page)]="currentSessionPage"
                    [maxSize]="3" [rotate]="true" [pageSize]="pageSessionSize">
                    <ng-template ngbPaginationPrevious>
                        <span>Previous</span>
                    </ng-template>
                    <ng-template ngbPaginationNext>Next</ng-template>
                </ngb-pagination>
            </div>
        </ng-container>
    </div>
</div>