import { Component, AfterViewInit, ElementRef, OnInit, Renderer2, Input, EventEmitter, Output, HostListener } from '@angular/core';
import { NgbDropdownConfig } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { HttpService } from 'src/app/services/http.service';
import * as Papa from 'papaparse'
@Component({
  selector: 'app-activity-report',
  templateUrl: './activity-report.component.html',
  styleUrls: ['./activity-report.component.css'],
  providers: [NgbDropdownConfig]
})
export class ActivityReportComponent implements AfterViewInit {
  @Output() nameEmitter = new EventEmitter<any>();
  @Input() userData: any
  constructor(
    private el: ElementRef,
    private renderer: Renderer2,
    config: NgbDropdownConfig,
    private httpSv: HttpService,
    private toster: ToastrService
  ) {
    // config.placement = 'bottom-start';
    config.autoClose = true;
  }
  ngOnInit(): void {
    this.getUserSubcritionDtls()
  }
  ngAfterViewInit() {
    setTimeout(() => {
      const icon = this.el.nativeElement.querySelector('.custom-icon');
      const input = this.el.nativeElement.querySelector('#datePicker');
      this.renderer.listen(icon, 'click', () => {
        input.focus();
      });
    })
  }
  currentPage = 1;
  currentSessionPage = 1;
  pageSize = 10
  pageSessionSize = 10
  items: any[] = [
    { id: 1, first: 'Mark', last: 'Otto', handle: '@mdo', details: 'Additional details for Mark' },
    { id: 2, first: 'Jacob', last: 'Thornton', handle: '@fat', details: 'Additional details for Jacob' },
    { id: 3, first: 'Larry', last: 'Bird', handle: '@twitter', details: 'Additional details for Larry' },
    { id: 4, first: 'Larry', last: 'Bird', handle: '@twitter', details: 'Additional details for Larry' },
    { id: 5, first: 'Larry', last: 'Bird', handle: '@twitter', details: 'Additional details for Larry' },
    { id: 6, first: 'Larry', last: 'Bird', handle: '@twitter', details: 'Additional details for Larry' },
    { id: 7, first: 'Larry', last: 'Bird', handle: '@twitter', details: 'Additional details for Larry' },
    { id: 8, first: 'Larry', last: 'Bird', handle: '@twitter', details: 'Additional details for Larry' },
    { id: 9, first: 'Larry', last: 'Bird', handle: '@twitter', details: 'Additional details for Larry' },
    { id: 11, first: 'Larry', last: 'Bird', handle: '@twitter', details: 'Additional details for Larry' },
    { id: 12, first: 'Larry', last: 'Bird', handle: '@twitter', details: 'Additional details for Larry' },
    { id: 13, first: 'Larry', last: 'Bird', handle: '@twitter', details: 'Additional details for Larry' },
    { id: 14, first: 'Larry', last: 'Bird', handle: '@twitter', details: 'Additional details for Larry' },
    { id: 15, first: 'Larry', last: 'Bird', handle: '@twitter', details: 'Additional details for Larry' },
    { id: 16, first: 'Larry', last: 'Bird', handle: '@twitter', details: 'Additional details for Larry' },
    { id: 17, first: 'Larry', last: 'Bird', handle: '@twitter', details: 'Additional details for Larry' },
    { id: 18, first: 'Larry', last: 'Bird', handle: '@twitter', details: 'Additional details for Larry' },
    { id: 19, first: 'Larry', last: 'Bird', handle: '@twitter', details: 'Additional details for Larry' },
    { id: 20, first: 'Larry', last: 'Bird', handle: '@twitter', details: 'Additional details for Larry' },
    { id: 21, first: 'Larry', last: 'Bird', handle: '@twitter', details: 'Additional details for Larry' },
    { id: 22, first: 'Larry', last: 'Bird', handle: '@twitter', details: 'Additional details for Larry' },
    { id: 23, first: 'Larry', last: 'Bird', handle: '@twitter', details: 'Additional details for Larry' },
    { id: 24, first: 'Larry', last: 'Bird', handle: '@twitter', details: 'Additional details for Larry' },
    { id: 25, first: 'Larry', last: 'Bird', handle: '@twitter', details: 'Additional details for Larry' },
    { id: 26, first: 'Larry', last: 'Bird', handle: '@twitter', details: 'Additional details for Larry' },
    { id: 27, first: 'Larry', last: 'Bird', handle: '@twitter', details: 'Additional details for Larry' },
    { id: 28, first: 'Larry', last: 'Bird', handle: '@twitter', details: 'Additional details for Larry' },
  ]
  expandedRow: number | null = null;
  toggleRow(rowId: number) {
    this.expandedRow = this.expandedRow === rowId ? null : rowId;
  }
  userSubscriptionDtls: any = [];
  pyInactiveFlag: boolean = false
  diInactiveFlag: boolean = false
  getUserSubcritionDtls() {
    this.httpSv.getUserDetails(this.userData.id).subscribe((res: any) => {
      if (res['status']) {
        this.userSubscriptionDtls = res.results;
        this.nameEmitter.emit(this.userSubscriptionDtls.user_dtls[0].name)
        if (this.userSubscriptionDtls.digital_dtls.length > 0) {
          if (this.userSubscriptionDtls.digital_dtls.every((ele: any) => ele.is_active == 0))
            this.diInactiveFlag = true
        }
        if (this.userSubscriptionDtls.physical_dtls.length > 0) {
          if (this.userSubscriptionDtls.physical_dtls.every((ele: any) => ele.is_active == 0))
            this.pyInactiveFlag = true
        }
      }
      else {
        this.toster.error(res.message, '', { timeOut: 2000 })
      }
    }, (err: any) => {
      this.toster.error('Oops! Something went wrong', '', { timeOut: 2000 })
    })
  }


  convertDate(dateString: any) {
    if (dateString) {
      const [datePart, timePart] = dateString.split(' ');
      const [year, month, day] = datePart.split('-');
      return `${day}/${month}/${year}`;
    }
    else return 'N/A'
  }

  convertEpochToDate(epoch: any) {
    if (epoch) {
      const date = new Date(epoch * 1000);

      const day = date.getUTCDate();
      const month = date.getUTCMonth() + 1;
      const year = date.getUTCFullYear();

      const formattedDay = day < 10 ? '0' + day : day;
      const formattedMonth = month < 10 ? '0' + month : month;

      return `${formattedDay}/${formattedMonth}/${year}`;
    }
    else return 'N/A'
  }
  // Buttons toggle logic  

  current_selection: string | null = null
  marginScrollFalg:boolean = false
  scrollDwon(){
    this.marginScrollFalg = true
    setTimeout(() => {
      let element = document.querySelector(".uses_table_main");
      if (element)
        element.scrollIntoView({ behavior: 'smooth' });
    }, 0)
  }
  handleDtlsUseageBtn(val: string) {
    if (val != this.current_selection)
      this.current_selection = val
    this.scrollDwon()
  }
  //       ----------------------License uses logic--------------------------
  liceseUsesStartDate: any = null
  liceseUsesEndDate: any = null
  lisenseUseDtls: any = []
  lisenseTableData: any[] = []
  onLicenseUsesDateChange() {
    if (this.liceseUsesStartDate && this.liceseUsesEndDate) {
      const dateStrat = new Date(this.liceseUsesStartDate);
      const dateEnd = new Date(this.liceseUsesEndDate);
      if (dateStrat > dateEnd) this.toster.info('Please Select Valid Date')
      else {
        this.httpSv.getUserLicenseDetails(this.userData.id, this.liceseUsesStartDate, this.liceseUsesEndDate).subscribe((res: any) => {
          if (res['status']) {
            this.lisenseUseDtls = res.results
            this.lisenseTableData = this.lisenseUseDtls.license_dtls
            this.liceseDtlsShowUpFlag = true
            this.marginScrollFalg = false
            setTimeout(() => {
              let element = document.querySelector(".show_licese_useage_table_btn");
              if (element)
                element.scrollIntoView({ behavior: 'smooth' });
            }, 0)
          } else {
            this.toster.error('Oops! something went wrong', '', { timeOut: 2000 })
          }
        }, (err: any) => {
          this.toster.error('Oops! something went wrong', '', { timeOut: 2000 })
        })
      }
    }
  }

  // License Dtls toggling logic
  liceseDtlsShowUpFlag: boolean = false;
  liceseTableDtlsShowUpFlag: boolean = false;
  showLicenseTable() {
    this.liceseTableDtlsShowUpFlag = true
    setTimeout(() => {
      let element = document.querySelector(".pagination_main_div");
      if (element)
        element.scrollIntoView({ behavior: 'smooth' });
    }, 0)

  }

  //       ----------------------Suscription Dtls logic--------------------------
  subscriptionDtlsStartDate: any = null
  subscriptionDtlsEndDate: any = null
  subscriptionDtlsDtls: any = []
  subscriptionDtlsData: any[] = []
  onSubscriptionDtlsDateChange() {
    if (this.subscriptionDtlsStartDate && this.subscriptionDtlsEndDate) {
      const dateStrat = new Date(this.subscriptionDtlsStartDate);
      const dateEnd = new Date(this.subscriptionDtlsEndDate);
      if (dateStrat > dateEnd) this.toster.info('Please Select Valid Date')
      else {
        this.httpSv.getUserSessionDetails(this.userData.id, this.subscriptionDtlsStartDate, this.subscriptionDtlsEndDate).subscribe((res: any) => {
          if (res['status']) {
            this.subscriptionDtlsDtls = res.results
            this.subscriptionDtlsData = this.subscriptionDtlsDtls.license_dtls
            this.subscriptionDtlsShowUpFlag = true
            this.marginScrollFalg = false
            setTimeout(() => {
              let element = document.querySelector(".pagination_main_div");
              if (element)
                element.scrollIntoView({ behavior: 'smooth' });
            }, 0)
          } else {
            this.toster.error('Oops! something went wrong', '', { timeOut: 2000 })
          }
        }, (err: any) => {
          this.toster.error('Oops! something went wrong', '', { timeOut: 2000 })
        })
      }
    }
  }
  // License Dtls toggling logic
  subscriptionDtlsShowUpFlag: boolean = false;
  convertEpochTimeslot(startTimestamp: any, endTimestamp: any, flag: boolean = false) {
    if (startTimestamp && endTimestamp) {
      const startDate = new Date(startTimestamp * 1000);
      const endDate = new Date(endTimestamp * 1000);

      // Format the dates
      const formattedStartDate = startDate.toLocaleString('en-GB', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        hour12: true
      });
      const formattedEndDate = endDate.toLocaleString('en-GB', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        hour12: true
      });

      // Extract only the date part
      const formattedDate = formattedStartDate.split(',')[0];

      // Extract the time part and remove seconds
      const formattedStartTime = formattedStartDate.split(',')[1].trim().replace(/:\d{2}$/, '');
      const formattedEndTime = formattedEndDate.split(',')[1].trim().replace(/:\d{2}$/, '');

      // Combine date and time
      if (!flag)
        return `${formattedDate} <br /> ${formattedStartTime} - ${formattedEndTime} Hrs`;
      else
        return `${formattedDate} - ${formattedStartTime} - ${formattedEndTime} Hrs`;
    }
    else {
      return 'N/A'
    }
  }
  getDurationByEpoch(start: any, end: any) {
    if (start && end) {
      const differenceInSeconds = end - start;

      // Convert the difference to hours and minutes
      const hours = Math.floor(differenceInSeconds / 3600);
      const minutes = Math.floor((differenceInSeconds % 3600) / 60);

      // Format the time as HH:MM
      return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}`;
    } else {
      return '00:00'
    }
  }





  // ------------------------------DATA Export------------------------------

  licenseUsesHeaderArray: any[] = ['Session Name', 'Client', 'Date', 'Status', 'Licenses Used']
  subscriptionDtlsHeaderArray: any[] = ['Session Name', 'Client', 'Date & Time','Player Planned', 'Players', 'Status', 'Session Run Time', 'Licenses Used', 'Game Played']
  jsonToCsvLicenseUses(items: any) {
    const csvData = [];
    csvData.push(this.licenseUsesHeaderArray);
    items.forEach((item: any) => {
      const rowData = [
        item.program_name,
        item.client_name,
        this.convertEpochToDate(item.program_date),
        item.isdeleted ? 'Deleted' : item.is_incompleted ? 'In completed' : 'Completed',
        item.license_used
      ];
      csvData.push(rowData);
    });
    return csvData
  }
  jsonToCsvSubscriptionDtls(items: any) {
    const csvData = [];
    csvData.push(this.subscriptionDtlsHeaderArray);
    items.forEach((item: any) => {
      let gamesData = []
      item.games.map((ele: any) => {
        let runTime = this.getDurationByEpoch(ele.start_time, ele.expiry_time);
        gamesData.push(ele.game_name + ' - ' + runTime)
      })
      if (item.games.length == 0) gamesData.push('N/A')
      const rowData = [
        item.program_name,
        item.client_name,
        this.convertEpochTimeslot(item.time_slots.start_datetime, item.time_slots.expiry_datetime, true),
        item.time_slots.planed_player ?? 'N/A',
        item.players ?? 0,
        item.isdeleted ? 'Deleted' : item.is_incompleted ? 'In completed' : 'Completed',
        this.getDurationByEpoch(item.session_starttime, item.session_expirytime),
        item.license_used,
        [gamesData]
      ];
      csvData.push(rowData);
    })
    return csvData
  }
  exportData(data: any, type: any) {
    let csvData: any
    if (type == 'licenseUses')
      csvData = this.jsonToCsvLicenseUses(data);
    else if (type == 'subDtls')
      csvData = this.jsonToCsvSubscriptionDtls(data);
    const csv = Papa.unparse(csvData);

    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', this.userSubscriptionDtls.user_dtls[0].name + '.csv');
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }
}
